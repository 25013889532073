<template>
  <InstallPwaButton />
  <div class="container my-2">
    <img src="img/logos/logo.png" alt="Efisnet" class="img-fluid" />
    <p v-if="route.name == 'activation-start'" class="alert alert-info">
      Verifique su acceso y/o solicitelo al administrador.
    </p>
    <router-view></router-view>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import { useRoute } from "vue-router";

import InstallPwaButton from "@/components/Configuration/InstallPwaButton.vue";

export default defineComponent({
  name: "ActivationLayout",
  components: {
    InstallPwaButton,
  },
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
});
</script>
<style lang="scss">
body {
  background-color: #f0f8ff;
}
</style>
