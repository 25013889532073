import { createRouter, createWebHistory } from "vue-router";
// import Home from '../views/Home.vue'
import MainLayout from "@/components/layouts/MainLayout.vue";
import ActivationLayout from "@/components/layouts/ActivationLayout.vue";

const routes = [
  {
    path: "/",
    name: "MainLayout",
    component: MainLayout,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("token")) {
        next();
      } else {
        next({ name: "activation-init" });
      }
    },
    children: [
      {
        path: "password-set",
        name: "password-set",
        component: () => import("@/views/Activation/PasswordUpdate.vue"),
      },
      {
        path: "token-get",
        name: "token-get",
        component: () => import("@/views/Auth/DinamicToken.vue"),
      },
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        component: () => import("@/views/Notifications/History.vue"),
      },
      {
        path: "configuration",
        name: "configuration",
        component: () => import("@/views/Configuration.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "activation-start",
    component: ActivationLayout,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("token")) {
        localStorage.removeItem("subscription");
        next();
      } else {
        next({ name: "MainLayout" });
      }
    },
    children: [
      {
        path: "",
        name: "activation-init",
        component: () => import("@/views/Activation/CodeConfirmation.vue"),
      },
      {
        path: ":phone_number",
        name: "activation-code",
        component: () => import("@/views/Activation/CodeConfirmation.vue"),
      },
    ],
  },
  // REGISTER
  // Activiate SMS Code
  // Actualizar Contraseña
  // Generador Token
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      const confirm = window.confirm(
        "¿Está seguro que desea cerrar sesión? \n Deberás volver a realizar el proceso de activación."
      );
      if (confirm) {
        localStorage.removeItem("token");
        localStorage.removeItem("subscription");
        next({ name: "activation-init" });
      } else {
        next({ name: "Home" });
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
