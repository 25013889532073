import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { openDB } from "idb";

import "bootstrap/dist/css/bootstrap.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Import custos assets/sass
// import "./assets/sass/element-ui.dark.scss";
// import "./assets/sass/plugins.scss";
import "./assets/sass/style.scss";

// Función para almacenar la notificación en IndexedDB
// const guardarNotificacionEnIndexedDB = async (notificacionStr) => {
//   const db = await openDB("efisnet_movil", 1, {
//     upgrade(db) {
//       const store = db.createObjectStore("notificaciones", {
//         keyPath: "timestamp",
//         autoIncrement: true,
//       });
//       store.createIndex("timestamp", "timestamp");
//     },
//   });

//   try {
//     // Parsear la notificación desde una cadena de texto a un objeto
//     const notificacion = JSON.parse(notificacionStr);

//     // Añadir un campo "timestamp" a la notificación
//     const timestampedNotificacion = {
//       ...notificacion,
//       timestamp: Date.now(),
//     };

//     const tx = db.transaction("notificaciones", "readwrite");
//     tx.store.add(timestampedNotificacion);
//     await tx.done;
//     console.log("Notificación guardada en IndexedDB");
//   } catch (error) {
//     console.error("Error al guardar la notificación en IndexedDB:", error);
//   }
// };

// navigator.serviceWorker.addEventListener("message", function (event) {
//   if (event.data.type === "push") {
//     // Aquí puedes manejar la notificación push recibida, si es necesario
//     console.log("Notificación push recibida:", event.data.data);
//     guardarNotificacionEnIndexedDB(event.data.data);
//   }
// });
library.add(fas);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  .mount("#app");
