<template>
  <div class="container py-2 vh-100">
    <div class="row">
      <div class="col d-flex">
        <MenuSlider class="w-15" />
        <img src="img/logos/logo.png" alt="Efisnet" class="img-fluid mx-auto" />
        <div class="w-15"></div>
      </div>
    </div>
    <div class="separator my-2"></div>
    <router-view></router-view>
    <small class="version text-gray-700">v. 1.7.0</small>
  </div>
</template>
<script>
import { defineComponent, onMounted } from "vue";

import MenuSlider from "@/components/Menu/MenuSlider.vue";

export default defineComponent({
  name: "MainLayout",
  components: {
    MenuSlider,
  },
  setup() {
    return {};
  },
});
</script>
<style lang="scss">
body {
  background-color: #f0f8ff;
}

.version {
  position: fixed !important;
  bottom: 0;
  right: 0.5em;
}
</style>
