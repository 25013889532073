<template>
  <div class="d-flex">
    <button
      class="btn btn-icon btn-lg btn-light-dark my-auto"
      @click="openMenu"
    >
      <font-awesome-icon icon="bars" />
    </button>

    <div :class="{ overlay: true, open: isOpen }" @click="closeMenu"></div>

    <div :class="{ 'side-drawer': true, open: isOpen }" @click.self="closeMenu">
      <button
        class="btn btn-dark btn-active-light btn-lg btn-icon close-btn"
        @click="closeMenu"
      >
        <font-awesome-icon icon="xmark" />
      </button>
      <!-- Aquí va tu menú -->
      <!-- 
      <ul class="list-unstyled">
        <li><a href="#">Link 1</a></li>
        <li><a href="#">Link 2</a></li>
        <li><a href="#">Link 3</a></li>
      </ul> -->
      <div
        id="kt_app_sidebar"
        class="app-sidebar flex-column d-flex"
        data-kt-drawer="true"
        data-kt-drawer-name="app-sidebar"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="225px"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
      >
        <div
          class="app-sidebar-logo px-6 justify-content-center d-flex"
          id="kt_app_sidebar_logo"
        >
          <a href="/" class="router-link-active"
            ><img
              alt="Logo"
              src="img/logos/logo-red-white.png"
              class="h-35px app-sidebar-logo-default" /><!----><img
              alt="Logo"
              src="img/logos/logo.png"
              class="h-30px app-sidebar-logo-minimize"
          /></a>
          <div
            id="kt_app_sidebar_toggle"
            class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="app-sidebar-minimize"
          >
            <span class="svg-icon svg-icon-2 rotate-180"
              ><svg
                class="svg-inline--fa fa-angle-right"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                ></path></svg
              ><!-- <i class="fa-solid fa-angle-right"></i> Font Awesome fontawesome.com --></span
            >
          </div>
        </div>
        <div class="separator my-5"></div>
        <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
          <div
            id="kt_app_sidebar_menu_wrapper"
            class="app-sidebar-wrapper hover-scroll-overlay-y my-5"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
            data-kt-scroll-wrappers="#kt_app_sidebar_menu"
            data-kt-scroll-offset="5px"
            data-kt-scroll-save-state="true"
            style="height: 595px"
          >
            <div
              id="#kt_app_sidebar_menu"
              class="menu menu-column menu-rounded menu-sub-indention px-3"
              data-kt-menu="true"
            >
              <div class="menu-item">
                <router-link :to="{ name: 'Home' }" class="menu-link">
                  <font-awesome-icon icon="home" class="menu-icon" />
                  <span class="gear">Inicio</span>
                </router-link>
              </div>
              <!---->
              <!-- <div class="menu-item pt-5">
                <div class="menu-content">
                  <span class="menu-heading fw-bold text-uppercase fs-7"
                    >Operación</span
                  >
                </div>
              </div> -->
              <div class="menu-item">
                <router-link :to="{ name: 'token-get' }" class="menu-link">
                  <font-awesome-icon icon="key" class="menu-icon" />
                  <span class="menu-title">Token Dinámico</span>
                </router-link>
              </div>
              <div class="separator my-5"></div>
              <div class="menu-item">
                <router-link :to="{ name: 'configuration' }" class="menu-link">
                  <font-awesome-icon icon="gear" class="menu-icon" />
                  <span class="menu-title">Configuración</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "MenuSlider",
  setup() {
    const isOpen = ref(false);

    function openMenu() {
      isOpen.value = true;
    }

    function closeMenu() {
      isOpen.value = false;
    }

    return {
      isOpen,
      openMenu,
      closeMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  top: 0;
  left: 0;
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: visibility 0.5s, opacity 0.5s;
  z-index: 2;
}

.overlay.open {
  visibility: visible;
  opacity: 1;
}

.side-drawer {
  width: 0;
  height: 100%;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #1e1e2d;
  overflow-x: hidden;
  transition: width 0.5s;
  padding-top: 20px;
}

.side-drawer.open {
  width: 250px;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0px;
}

.router-link-exact-active {
  transition: color 0.2s ease;
  background-color: #2a2a3c;
  color: #ffffff;
}

a {
  color: #9d9da6;
}

.separator {
  border-bottom: 1px dashed #393945;
}
</style>
