<!-- InstallPwaButton.vue -->

<template>
  <div v-if="showButton" class="bg-dark p-5">
    <div class="col">
      <button
        v-if="showButton"
        @click="installPwa"
        class="btn btn-success w-100 py-5"
      >
        <span class="fs-2"
          ><font-awesome-icon icon="download" class="me-2" />
          <strong>Instalar APP</strong></span
        >
        <small class="d-block font-italic"
          >Una vez instalada, es necesario cerrar por completo y abrir desde el
          icono.</small
        >
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    const showButton = ref(false);
    let deferredPrompt = null;

    const installPwa = () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("El usuario aceptó instalar la PWA");
          } else {
            console.log("El usuario rechazó la instalación de la PWA");
          }
        });

        deferredPrompt = null;
      }
    };

    onMounted(() => {
      const manifest = document.querySelector('link[rel="manifest"]');
      if (manifest) {
        window.addEventListener("beforeinstallprompt", (event) => {
          event.preventDefault();
          deferredPrompt = event;
          showButton.value = true;
        });
      }
    });

    return { showButton, installPwa };
  },
};
</script>
